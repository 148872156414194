import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import React from "react";

export default function ContactInfoWidget() {
  return (
    <ul className="cs_contact_widget">
      <Link
        to="https://www.instagram.com/acsmiles_nagpur/"
        target="_blank"
        className=""
      >
        <li className="mb-3">
          <i className="cs_accent_bg mr-2">
            <Icon icon="fa-brands:instagram" />
          </i>
          @acsmiles_nagpur
        </li>
      </Link>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="fluent:call-24-regular" />
        </i>
        +91 7020819788
      </li>
      <li>
        <i className="cs_accent_bg">
          <Icon icon="bi:envelope" />
        </i>
        acsmiles31@gmail.com
      </li>
    </ul>
  );
}
