import React from "react";
import { Link } from "react-scroll";

export default function MenuWidget({ data }) {
  return (
    <ul className="cs_menu_widget cs_mp0">
      {data?.map((item, index) => (
        <li key={index}>
          <Link to={item.href} smooth={true} duration={500}>
            {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
}
