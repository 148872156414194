import React from "react";
import SectionHeading from "../../SectionHeading";
import Spacing from "../../Spacing";
// import Testimonial from "../../Testimonial";

export default function TestimonialSection({ sectionTitle, sectionTitleDown }) {
  return (
    <div className="container">
      <SectionHeading
        title={sectionTitle}
        titleDown={sectionTitleDown}
        center
      />
      <Spacing md="72" lg="50" />
      {/* <Testimonial /> */}

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto">
          <div class="flex items-start justify-center">
            <img src="/images/icons/quote.svg" alt="Icon" class="mr-3" />
            <p class="leading-relaxed text-lg text-center pt-2">
              Dr. Anuja is known for her compassionate and gentle approach,
              ensuring patients feel at ease during their dental visits. She
              takes the time to listen to their concerns, explain treatment
              options, and create personalized treatment plans. Her goal is to
              help every patient achieve optimal oral health and a confident
              smile.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
