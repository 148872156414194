import React from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import AppointmentSection from "../Section/AppointmentSection";
import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
import { Element } from "react-scroll";

const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Compassion",
    subTitle:
      "We understand that visiting the dentist can be stressful for some. Our friendly team is dedicated to creating a warm, welcoming atmosphere where you feel comfortable and at ease. We listen attentively to your concerns and strive to make every visit a positive experience.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Excellence",
    subTitle:
      "Your smile is our top priority. We are committed to providing exceptional dental care using the latest techniques and technology. Our team continually updates their knowledge and skills to deliver the best possible treatment outcomes.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Integrity",
    subTitle: `We believe in building trust through transparent communication and ethical practices. We will always explain your treatment options clearly, answer any questions you may have, and help you make informed decisions about your oral health. Your well-being is our primary concern.
`,
  },
];

const awardData = [
  {
    title: "Dental Surgeon",
    subTitle:
      "Dr. Anuja holds a Bachelor of Dental Surgery (BDS) degree and is a registered dental practitioner (Reg. No. A-50493).",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Years of Experience",
    subTitle:
      "She has dedicated years to honing her skills and expertise in various aspects of dentistry.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Continued Education",
    subTitle:
      "Dr. Anuja stays up-to-date with the latest advancements in dental technology and techniques to offer her patients the best possible care.",
    iconUrl: "images/icons/award.svg",
  },
];
const departmentData = [
  {
    title: "General Dentistry",
    iconUrl: "images/home_1/department_icon_1.svg",
    href: "/",
  },
  {
    title: "Cosmetic Dentistry",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/",
  },
  {
    title: "Restorative Dentistry",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/",
  },
  {
    title: "Pediatric Dentistry",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Crowns and bridges ",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Cosmetic procedures",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Dental Implants",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Invisalign",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Orthodontic braces",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Scaling and Teeth whitening",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Wisdom tooth removal ",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Pediatric treatments",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
  {
    title: "Fillings and Root canal treatments",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/",
  },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Your Smile, <br />
Our Expertise"
        subTitle="Welcome to ac smiles, your trusted family dentist. We offer 
comprehensive dental care for all ages, from routine checkups
 and cleanings to cosmetic dentistry and restorative
 treatments. Our experienced team is committed to 
providing personalized care and ensuring your visit is 
comfortable and stress-free"
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/hero_img.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: "Morning",
            subTitle: "10:00 AM to 2:00 PM",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Evening",
            subTitle: "5:00 PM to 9:00 PM",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Sunday",
            subTitle: "By prior appoinments only.",
            iconUrl: "/images/icons/ambulance.svg",
          },
        ]}
        btnText="Book Now"
        btnUrl="/appointments"
      />
      {/* Start Feature Section */}
      <Element name="values">
        <Section
          topMd={185}
          topLg={140}
          topXl={100}
          bottomMd={185}
          bottomLg={140}
          bottomXl={100}
        >
          <FeaturesSection sectionTitle="Our Values" data={featureListData} />
        </Section>
      </Element>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Element name="about">
        <Section>
          <AboutSection
            imgUrl="/images/home_1/about.png"
            spiningImgUrl="/images/home_1/about_mini.svg"
            title="Know your Doctor"
            subTitle="Get to Know"
            featureList={[
              {
                featureListTitle:
                  "Dr. Anuja Kishorrao Chanekar, Your Trusted Dental Surgeon in Nagpur",
                featureListSubTitle:
                  "Dr. Anuja Kishorrao Chanekar is a highly skilled and experienced dental surgeon dedicated to providing exceptional oral care to patients of all ages. She believes in a patient-centered approach, ensuring every individual receives personalized treatment and a comfortable dental experience.",
              },
            ]}
          />
        </Section>
      </Element>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Element name="departments">
        <Section topMd={185} topLg={150} topXl={110}>
          <DepartmentSection
            sectionTitle="Specialties and Services"
            bgUrl="images/home_1/department_bg.svg"
            data={departmentData}
          />
        </Section>
      </Element>

      {/* End Departments Section */}
      {/* Start Award Section */}

      <Element name="qualifications">
        <Section topMd={185} topLg={140} topXl={100}>
          <AwardSection
            sectionTitle="Qualifications and Experience"
            data={awardData}
          />
        </Section>
      </Element>

      {/* End Award Section */}
      {/* Start Testimonial */}
      <Element name="testimonials">
        <Section
          topMd={185}
          topLg={140}
          topXl={100}
          bottomMd={200}
          bottomLg={150}
          bottomXl={110}
        >
          <TestimonialSection
            sectionTitle="Commitment to"
            sectionTitleDown="Of our clients"
          />
        </Section>
      </Element>

      {/* End Testimonial */}
      {/* Start Banner Section */}
      {/* <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/home_1/cta_img.png"
          title="Don’t Let Your Health Take a Backseat!"
          subTitle="Schedule an appointment with one of our experienced medical professionals today!"
        />
      </Section> */}
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      <Element name="appointment">
        <Section topMd={190} topLg={145} topXl={105} id="appointment">
          <AppointmentSection
            sectionTitle="Appointment"
            sectionTitleUp="BOOK AN"
            imgUrl="/images/home_1/appoinmentmain.png"
          />
        </Section>
      </Element>

      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section> */}
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      {/* <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <BrandsSection data={brandData} />
      </Section> */}
      {/* End Brand Section */}
    </>
  );
}
